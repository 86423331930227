import React from 'react';
import ChatList from "../About/About";
import ChatContent from "../Contact/Contact";
import UserProfile from "../Services/Services";
import logo from "./../../images/logo-icon.png";
import logo1 from "./../../images/logo-icon-top.png";
import slider1 from "./../../images/slider-1.jpg";
import slider2 from "./../../images/slider-2.jpg";
import img1 from "./../../images/hal.png";
import { useState, useEffect } from 'react';

const Home = () => {

    const cities = ["Mumbai", "Delhi", "Chennai", "Kolkata", "Bangalore", "Hyderabad", "Ahmedabad", "Jaipur", "Lucknow", "Patna", "Bhopal", "Chandigarh", "Raipur", "Ranchi", "Thiruvananthapuram", "Shimla", "Guwahati", "Bhubaneswar", "Agartala", "Aizawl", "Kohima", "Itanagar", "Imphal", "Gangtok", "Panaji"];
    const [temps, setTemps] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const weatherData = [];
            for (const city of cities) {
                const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=f11562d3fbf5559d81fc6b5fdae7ede5`);
                const data = await response.json();

                const temperatureInKelvin = data.main.temp;
                const temperatureInCelsius = temperatureInKelvin - 273.15;
                const temperatureInFahrenheit = (temperatureInCelsius * 9 / 5) + 32;

                weatherData.push({
                    city,
                    temperature: {
                        celsius: temperatureInCelsius.toFixed(2),
                        fahrenheit: temperatureInFahrenheit.toFixed(2)
                    }
                });
            }
            setTemps(weatherData);
        };

        fetchData();
    }, [cities]);






    return (
        <>
            <section className="top-header">
                <div className="">
                    <div className="">
                        <div className="">
                            <div className="top-header-content">
                                <div className="main-banner-content">
                                    <h1>किसान सेवा</h1>
                                    <p>सुविधा आपकी, सफलता हमारी</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="banner">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-9">
                            <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img src={slider1} className="d-block w-100" alt="..." />
                                    </div>
                                    <div className="carousel-item">
                                        <img src={slider2} className="d-block w-100" alt="..." />
                                    </div>
                                    <div className="carousel-item">
                                        <img src={slider1} className="d-block w-100" alt="..." />
                                    </div>
                                    <div className="carousel-item">
                                        <img src={slider2} className="d-block w-100" alt="..." />
                                    </div>

                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade"
                                    data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade"
                                    data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="advertisement ">

                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <section className='about-us'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-8 m-auto'>
                            <div className='main-header'>
                                <h1>हमारे बारे में</h1>
                                <span></span>
                            </div>
                            <p>भारत के इतिहास में किसान सेवा पोर्टल किसानो के हित में तैयार एक ऐसा पोर्टल है, जिसके माध्यम से किसानो को सशक्त करने और किसानो तक खेती से जुड़ी सभी सुविधाओ को आसानी से पहुंचाने में मदद करना है।
                            </p>
                            <p>
                                इस पोर्टल के माध्यम से किसानो को सुविधाओ के साथ ही आर्थिक रूप से मदद करने की भी पूरी कोशिश की जाएगी।
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="wthr-frcst">
                <div className='city-list'>
                    <ul>
                        {temps.map((item) => (
                            <li key={item.city}>
                                <span>{item.city}:</span>
                                <span>{item.temperature.celsius} °C</span>
                                <span>{item.temperature.fahrenheit} °F</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </section>
        </>


    )
}

export default Home