import React from 'react';
import { Link } from "react-router-dom";

const Nav = () => {

  return (
    <>
      <nav className="navbar mobile-menu fixed-top navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <Link className="navbar-brand text-white" to="/">Kisan Sewa</Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon">
              <span className='burger-menu' ></span>
              <span className='burger-menu' ></span>
              <span className='burger-menu' ></span>
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 m-auto">
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/">होम</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link " aria-current="page" to="/services" >सेवाएं</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link " aria-current="page" to="/news">समाचार</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link " aria-current="page" to="/market">बाजार</Link>
              </li>
              <li className="nav-item" >
                <Link className="nav-link" aria-current="page" to="/about" >हमारे बारे में</Link>
              </li>
              <li className="nav-item" >
                <Link className="nav-link" aria-current="page" to="/contact" >संपर्क</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" aria-current="page" to="/solution">सुझाव</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Nav