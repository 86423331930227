import React from 'react';
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Layout from './layout/Layout';
import Services from "./components/Services/Services";
import ComingSoon from "./components/ComingSoon/ComingSoon";
import "./App.css";
import { Route, Routes, Outlet, BrowserRouter as Router } from "react-router-dom";

function App() {

  const PublicRoute = () => {
    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  };

  return (

    <Router>
      <Routes>
      <Route exact element={<PublicRoute />}>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/services' element={<Services />} />
        <Route path='/solution' element={<ComingSoon />} />
        <Route path='/news' element={<ComingSoon />} />
        <Route path='/market' element={<ComingSoon />} />
        </Route>
      </Routes>
    </Router>

  );
}

export default App;
