import React from 'react';
import logo1 from "./../../images/logo-icon-top.png";
import team1 from "./../../images/ceo.jpeg";
import team2 from "./../../images/managing-director.jpeg";
import team3 from "./../../images/marketing-head.jpeg";

const About = () => {
  return (
    <>
      <section className="top-header">
        <div className="">
          <div className="">
            <div className="">
              <div className="top-header-content">
                <div className="main-banner-content">
                  <h1>किसान सेवा</h1>
                  <p>सुविधा आपकी, सफलता हमारी</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='about-us mt-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8 m-auto'>
              <div className='main-header'>
                <h1>हमारे बारे में</h1>
                <span></span>
              </div>
              <p>भारत के इतिहास में किसान सेवा पोर्टल किसानो के हित में तैयार एक ऐसा पोर्टल है, जिसके माध्यम से किसानो को सशक्त करने और किसानो तक खेती से जुड़ी सभी सुविधाओ को आसानी से पहुंचाने में मदद करना है।
              </p>
              <p>
                इस पोर्टल के माध्यम से किसानो को सुविधाओ के साथ ही आर्थिक रूप से मदद करने की भी पूरी कोशिश की जाएगी।
              </p>
            </div>
          </div>
        </div>
      </section>


      <section className='team'>
        <div className='container'>

          <div className='row'>
            <div className='col-md-8 m-auto'>
              <div className='main-header'>
                <h1>हमारी टीम</h1>
                <span></span>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-4'>
              <div className='team-members'>
                <div className='team-img'>
                  <img src={team1} />
                </div>
                <span className='team-name' >
                  Er. Abhay Kumar
                </span>
                <span className='team-post' >
                  CEO
                </span>
                <span className='team-mail' >
                  kisansewabihar3@gmail.com
                </span>
                <span className='team-number' >
                  +91 7701871551
                </span>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='team-members'>
                <div className='team-img'>
                  <img src={team2} />
                </div>
                <span className='team-name' >
                  Mr. A.K. Ojha
                </span>
                <span className='team-post' >
                  Managing Director
                </span>
                <span className='team-mail' >
                  kisansewabihar2@gmail.com
                </span>
                <span className='team-number' >
                  +91 7701871551
                </span>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='team-members'>
                <div className='team-img'>
                  <img src={team3} />
                </div>
                <span className='team-name' >
                  Nirbhay Kr. Pandey
                </span>
                <span className='team-post' >
                  Marketing Head
                </span>
                <span className='team-mail' >
                  kisansewabihar1@gmail.com
                </span>
                <span className='team-number' >
                  +91 7701871551
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default About