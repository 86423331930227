import React from 'react';
import logo from "./../../images/logo-icon.png";

const Footer = () => {
  return (
    <>

      <footer class="main-footer footer-style-one">

        <div className='container' >
          <div className='row'>
            <div className='col-md-6'>
              <div className='footer-logo'>
                <img src={logo} />
              </div>
            </div>
            <div className='col-md-6 m-auto'>
              <div class="rounded-social-buttons">
                <a class="social-button facebook" href='https://www.facebook.com/profile.php?id=61555373061380' target="_blank"><i
                  class="fab fa-facebook-f"></i></a>
                <a class="social-button twitter" href='https://twitter.com/KISANSEWA2024 ' target="_blank"><i class="fab fa-twitter"></i></a>
                <a class="social-button linkedin" target="_blank"><i
                  class="fab fa-linkedin"></i></a>
                <a class="social-button youtube" target="_blank"><i class="fab fa-youtube"></i></a>
                <a class="social-button instagram" target="_blank"><i
                  class="fab fa-instagram"></i></a>
              </div>
            </div>
          </div>
        </div>
        <div class="inner-container">
                <div class="copyright-text">© Copyrights 2024 Kisan Sewa All rights reserved. </div>
              </div>
      </footer>

    </>
  )
}

export default Footer