import React from 'react';
import Footer from "../components/Footer/Footer";
import Nav from "../components/Nav/Nav";

const Layout = (props) => {
  return (
    <>
      <div className="main">
        <div className="main__content">
          <Nav />
          <div className="mb-auto">{props.children}</div>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default Layout