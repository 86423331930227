import React from 'react';
// import logo from "./../../images/logo.png";
import logo from "./../../images/logo-icon.png";
import logo1 from "./../../images/logo-icon-top.png";
const Contact = () => {
  return (
    <>
      <section className="top-header">
        <div className="">
          <div className="">
            <div className="">
              <div className="top-header-content">
                <div className="main-banner-content">
                  <h1>किसान सेवा</h1>
                  <p>सुविधा आपकी, सफलता हमारी</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='contact-us'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 m-auto'>
              <div className='contact-us-left'>
                <h1>Contact Us</h1>
                <p>भारत के इतिहास में किसान सेवा पोर्टल किसानो के हित में तैयार एक ऐसा पोर्टल है,
                  जिसके माध्यम से किसानो को सशक्त करने और किसानो
                  तक खेती से जुड़ी सभी सुविधाओ को आसानी से पहुंचाने में मदद करना है।</p>
                <div class="contacat-content">
                  <a class="social-button facebook" target="_blank"><i class="fa-solid fa-envelope"></i>kisansewabihar@gmail.com</a>
                  <a class="social-button twitter" target="_blank"><i class="fa-solid fa-mobile"></i>+91 922-900-1551 / +91 922-901-1551</a>
                  <a class="social-button linkedin" target="_blank"><i class="fa-solid fa-location-dot"></i>East Champaran Bihar</a>
                </div>
              </div>

            </div>
            <div className='col-md-6'>
              <div className='contact-us-right'>
                <h1>Contact Us</h1>
                <div className='row'>
                  <div className='col-md-6'>
                    <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="First-Name" />
                  </div>
                  <div className='col-md-6'>
                    <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Last-Name" />
                  </div>
                  <div className='col-md-12'>
                    <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Email" />
                  </div>
                  <div className='col-md-12'>
                    <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Phone Number" />
                  </div>
                  <div className='col-md-12'>
                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                  </div>
                </div>
                <a href='https://docs.google.com/forms/d/e/1FAIpQLScJEbDF8rsOhfItFTiSnmgIYgtPjEuAP4cS-8dAQvleXClCqg/viewform?vc=0&c=0&w=1&flr=0' target='_blank' class="btn contact-link btn-success">Submit</a>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Contact