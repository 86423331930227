import React from 'react';

const ComingSoon = () => {
  return (
    <>
    <div className='comingsoon' >
    <h1>Coming Soon</h1>
    </div>
        
    </>
  )
}

export default ComingSoon