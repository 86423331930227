import React from 'react';
import img1 from "./../../images/hal.png";
import logo1 from "./../../images/logo-icon-top.png";
import tractor from "./../../images/tractor.png";
import thresor from "./../../images/thresor.png";
import sugarcane from "./../../images/sugarcane.png";
import multicropthresor from "./../../images/multicropthresor.png";
import powertiller from "./../../images/powertiller.png";
import bailer from "./../../images/bailer.png";
import electricpump from "./../../images/electricpump.png";
import dieselpump from "./../../images/dieselpump.png";
import pipe from "./../../images/pipe.png";
import batteryspryer from "./../../images/batteryspryer.png";
import manualspryer from "./../../images/manualspryer.png";
import dieselspryer from "./../../images/dieselspryer.png";
import dhanropni from "./../../images/dhanropni.png";
import sohni from "./../../images/sohni.png";
import katni from "./../../images/katni.png";
import maaldulai from "./../../images/maaldulai.png";
import krishiadviser from "./../../images/krishiadviser.png";
import harrow from "./../../images/harrow.png";
import cultivator from "./../../images/cultivator.png";
import rotavator from "./../../images/rotavator.png";
import plau from "./../../images/plau.png";
import subsiler from "./../../images/subsiler.png";









const Services = () => {
    return (
        <>
            <section className="top-header">
                <div className="">
                    <div className="">
                        <div className="">
                            <div className="top-header-content">
                                <div className="main-banner-content">
                                    <h1>किसान सेवा</h1>
                                    <p>सुविधा आपकी, सफलता हमारी</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="our-services">
                <div className="container">
                    <div className='row mb-5'>
                        <div className='col-md-8 m-auto'>
                            <div className='main-header'>
                                <h1>हमारी सेवाएं</h1>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <h2 className="services-heading">जुताई</h2>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-md-4">
                            <div className="item">
                                <div className="testimonial-one__single">
                                    <div className="testimonial-one__client-details-box">

                                        <img src={img1} alt="" />
                                    </div>
                                    <div className="testimonial-one__client-details">
                                        <h4
                                            className="testimonial-one__client-name testimonial-one__client-name testimonial-one__client-name">
                                            हल</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="item">
                                <div className="testimonial-one__single">
                                    <div className="testimonial-one__client-details-box">

                                        <img src={harrow} alt="" />
                                    </div>
                                    <div className="testimonial-one__client-details">
                                        <h4
                                            className="testimonial-one__client-name testimonial-one__client-name testimonial-one__client-name">
                                            हैरो</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="item">
                                <div className="testimonial-one__single">
                                    <div className="testimonial-one__client-details-box">

                                        <img src={cultivator} alt="" />
                                    </div>
                                    <div className="testimonial-one__client-details">
                                        <h4
                                            className="testimonial-one__client-name testimonial-one__client-name testimonial-one__client-name">
                                            कल्टीवेटर</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="item">
                                <div className="testimonial-one__single">
                                    <div className="testimonial-one__client-details-box">

                                        <img src={rotavator} alt="" />
                                    </div>
                                    <div className="testimonial-one__client-details">
                                        <h4
                                            className="testimonial-one__client-name testimonial-one__client-name testimonial-one__client-name">
                                            रोटावेटर</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="item">
                                <div className="testimonial-one__single">
                                    <div className="testimonial-one__client-details-box">

                                        <img src={plau} alt="" />
                                    </div>
                                    <div className="testimonial-one__client-details">
                                        <h4
                                            className="testimonial-one__client-name testimonial-one__client-name testimonial-one__client-name">
                                            प्लाउ</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="item">
                                <div className="testimonial-one__single">
                                    <div className="testimonial-one__client-details-box">

                                        <img src={subsiler} alt="" />
                                    </div>
                                    <div className="testimonial-one__client-details">
                                        <h4
                                            className="testimonial-one__client-name testimonial-one__client-name testimonial-one__client-name">
                                            सबसाइलर</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <h2 className="services-heading">सिचाई</h2>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-md-4">
                            <div className="item">
                                <div className="testimonial-one__single">
                                    <div className="testimonial-one__client-details-box">

                                        <img src={electricpump} alt="" />
                                    </div>
                                    <div className="testimonial-one__client-details">
                                        <h4
                                            className="testimonial-one__client-name testimonial-one__client-name testimonial-one__client-name">
                                            इलेक्ट्रिक पंप</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="item">
                                <div className="testimonial-one__single">
                                    <div className="testimonial-one__client-details-box">

                                        <img src={dieselpump} alt="" />
                                    </div>
                                    <div className="testimonial-one__client-details">
                                        <h4
                                            className="testimonial-one__client-name testimonial-one__client-name testimonial-one__client-name">
                                            डीजल पंप</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="item">
                                <div className="testimonial-one__single">
                                    <div className="testimonial-one__client-details-box">

                                        <img src={pipe} alt="" />
                                    </div>
                                    <div className="testimonial-one__client-details">
                                        <h4
                                            className="testimonial-one__client-name testimonial-one__client-name testimonial-one__client-name">
                                            पाइप</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="item">
                                <div className="testimonial-one__single">
                                    <div className="testimonial-one__client-details-box">

                                        <img src={batteryspryer} alt="" />
                                    </div>
                                    <div className="testimonial-one__client-details">
                                        <h4
                                            className="testimonial-one__client-name testimonial-one__client-name testimonial-one__client-name">
                                            बैट्री स्प्रेयर</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="item">
                                <div className="testimonial-one__single">
                                    <div className="testimonial-one__client-details-box">

                                        <img src={manualspryer} alt="" />
                                    </div>
                                    <div className="testimonial-one__client-details">
                                        <h4
                                            className="testimonial-one__client-name testimonial-one__client-name testimonial-one__client-name">
                                            मैन्युअल स्प्रेयर</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="item">
                                <div className="testimonial-one__single">
                                    <div className="testimonial-one__client-details-box">

                                        <img src={dieselspryer} alt="" />
                                    </div>
                                    <div className="testimonial-one__client-details">
                                        <h4
                                            className="testimonial-one__client-name testimonial-one__client-name testimonial-one__client-name">
                                            डीजल स्प्रेयर</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <h2 className="services-heading">मैन पावर</h2>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-md-4">
                            <div className="item">
                                <div className="testimonial-one__single">
                                    <div className="testimonial-one__client-details-box">

                                        <img src={dhanropni} alt="" />
                                    </div>
                                    <div className="testimonial-one__client-details">
                                        <h4
                                            className="testimonial-one__client-name testimonial-one__client-name testimonial-one__client-name">
                                            रोपनी</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="item">
                                <div className="testimonial-one__single">
                                    <div className="testimonial-one__client-details-box">

                                        <img src={sohni} alt="" />
                                    </div>
                                    <div className="testimonial-one__client-details">
                                        <h4
                                            className="testimonial-one__client-name testimonial-one__client-name testimonial-one__client-name">
                                            सोहनी</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="item">
                                <div className="testimonial-one__single">
                                    <div className="testimonial-one__client-details-box">

                                        <img src={katni} alt="" />
                                    </div>
                                    <div className="testimonial-one__client-details">
                                        <h4
                                            className="testimonial-one__client-name testimonial-one__client-name testimonial-one__client-name">
                                            कटनी</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="item">
                                <div className="testimonial-one__single">
                                    <div className="testimonial-one__client-details-box">

                                        <img src={maaldulai} alt="" />
                                    </div>
                                    <div className="testimonial-one__client-details">
                                        <h4
                                            className="testimonial-one__client-name testimonial-one__client-name testimonial-one__client-name">
                                            माल ढुलाई</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="item">
                                <div className="testimonial-one__single">
                                    <div className="testimonial-one__client-details-box">

                                        <img src={krishiadviser} alt="" />
                                    </div>
                                    <div className="testimonial-one__client-details">
                                        <h4
                                            className="testimonial-one__client-name testimonial-one__client-name testimonial-one__client-name">
                                            कृषि सलाहकार</h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <h2 className="services-heading">यंत्र</h2>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-md-4">
                            <div className="item">
                                <div className="testimonial-one__single">
                                    <div className="testimonial-one__client-details-box">

                                        <img src={tractor} alt="" />
                                    </div>
                                    <div className="testimonial-one__client-details">
                                        <h4
                                            className="testimonial-one__client-name testimonial-one__client-name testimonial-one__client-name">ट्रेक्टर</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="item">
                                <div className="testimonial-one__single">
                                    <div className="testimonial-one__client-details-box">

                                        <img src={thresor} alt="" />
                                    </div>
                                    <div className="testimonial-one__client-details">
                                        <h4
                                            className="testimonial-one__client-name testimonial-one__client-name testimonial-one__client-name">
                                            थ्रेसर</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="item">
                                <div className="testimonial-one__single">
                                    <div className="testimonial-one__client-details-box">

                                        <img src={sugarcane} alt="" />
                                    </div>
                                    <div className="testimonial-one__client-details">
                                        <h4
                                            className="testimonial-one__client-name testimonial-one__client-name testimonial-one__client-name">
                                            सुगरकेन कटर</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="item">
                                <div className="testimonial-one__single">
                                    <div className="testimonial-one__client-details-box">

                                        <img src={multicropthresor} alt="" />
                                    </div>
                                    <div className="testimonial-one__client-details">
                                        <h4
                                            className="testimonial-one__client-name testimonial-one__client-name testimonial-one__client-name">
                                            मल्टीक्रॉप थ्रेसर</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="item">
                                <div className="testimonial-one__single">
                                    <div className="testimonial-one__client-details-box">

                                        <img src={powertiller} alt="" />
                                    </div>
                                    <div className="testimonial-one__client-details">
                                        <h4
                                            className="testimonial-one__client-name testimonial-one__client-name testimonial-one__client-name">
                                            पावर टिलर</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="item">
                                <div className="testimonial-one__single">
                                    <div className="testimonial-one__client-details-box">

                                        <img src={bailer} alt="" />
                                    </div>
                                    <div className="testimonial-one__client-details">
                                        <h4
                                            className="testimonial-one__client-name testimonial-one__client-name testimonial-one__client-name">
                                            बेलर</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </section>
        </>
    )
}

export default Services